import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  setHeight() {
    const height = window.visualViewport.height.toString() + "px";
    this.element.style.height = height;
    document.getElementsByTagName("html")[0].style.height = height;
    window.scrollTo(0, 0);
  }

  connect() {
    // TODO: remove listeners
    window.addEventListener("resize", () => {
      this.setHeight();
    });
    visualViewport.addEventListener("resize", () => {
      this.setHeight();
    });
    this.setHeight();
  }
}
