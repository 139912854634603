import React, { useEffect, useState, useRef } from "react";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import { Avatar } from "./Avatar";

export function AvatarSetting({}) {
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const client = new SharedMatrix().getClient();
  const getInfo = async () => {
    const info = await client.getProfileInfo(client.getUserId());
    if (info.avatar_url) {
    } else {
    }
  };
  const upload = async () => {
    const response = await client.uploadContent(file);
    await client.setAvatarUrl(response.content_uri);
    if (inputRef.current) inputRef.current.value = null;
    setFile(null);
  };

  useEffect(() => {
    getInfo();
  });
  return React.createElement(
    "div",
    {},
    React.createElement(Avatar, { userId: client.getUserId() }),
    React.createElement("input", {
      ref: inputRef,
      type: "file",
      accept: "image/png, image/jpeg",
      onChange: (event) => setFile(event.target.files[0]),
    }),
    file && React.createElement("button", { onClick: upload }, "UPLOAD"),
  );
}
