import React, { useState } from "react";
import { Modal } from "./Modal";
import { SharedMatrix } from "../../mks-matrix/shared-matrix";

const initialState = {
  providedKey: "",
  backupByKeyError: null,
};
export function BackupByKey({ close, reset }) {
  const [state, setState] = useState(initialState);

  const tryToBackupWithProvidedKey = async () => {
    const matrix = new SharedMatrix().getMatrix();
    const error = await matrix.tryToBackup(state.providedKey);
    setState({ ...state, backupByKeyError: error });
  };

  return React.createElement(
    Modal,
    {
      title: "Schluessel eingeben",
      close: close,
    },
    React.createElement("input", {
      className: "border full-width margin-10-0 border-box",
      onChange: () =>
        setState({
          ...state,
          providedKey: event.target.value,
          backupByKeyError: null,
        }),
    }),
    state.backupByKeyError &&
      React.createElement(
        "div",
        { className: "color-red padding-bottom-12" },
        state.backupByKeyError,
      ),
    React.createElement(
      "button",
      { className: "full-width", onClick: tryToBackupWithProvidedKey },
      "Bestätigen",
    ),
    React.createElement(
      "button",
      { className: "full-width background-red margin-top-4", onClick: reset },
      "Zurücksetzen",
    ),
  );
}
