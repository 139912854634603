import React, { useRef, useEffect } from "react";

export function Audio({ feed }) {
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current && feed?.stream) {
      audioRef.current.srcObject = feed.stream;
    }
  }, [feed.stream]);
  return React.createElement("audio", {
    ref: audioRef,
    autoPlay: true,
    controls: false,
    muted: feed.isLocal(),
  });
}
