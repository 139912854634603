import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "main", "grid", "section", "nav"];

  open({ params }) {
    this.menuTargets.forEach((element) => {
      element.classList.remove("active");
    });
    this.gridTarget.classList.add("active");
    this.mainTarget.classList.add("active");
    this.sectionTargets.forEach((element, index) => {
      if (index === params.index) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
    this.navTargets.forEach((element, index) => {
      if (index === params.index) {
        element.classList.add("selected");
      } else {
        element.classList.remove("selected");
      }
    });
  }

  back() {
    this.menuTargets.forEach((element) => {
      element.classList.add("active");
    });
    this.gridTarget.classList.remove("active");
    this.mainTarget.classList.remove("active");
    this.sectionTargets.forEach((element, index) => {
      element.classList.remove("active");
    });
  }
}
