import React, { useEffect } from "react";
import { StrictMode } from "react";
import { useState } from "react";
import { Video } from "./Video";
import { Audio } from "./Audio";
import { Video as VideoIcon } from "./icons/Video";
import { Phone as AudioIcon } from "./icons/Phone";
import { SharedMatrix } from "../mks-matrix/shared-matrix";

const initialState = {
  feeds: [],
  state: [],
  participants: [],
  activeSpeaker: null,
  isMicrophoneMuted: false,
  isLocalVideoMuted: false,
};

function recalculateLayout() {
  const gallery = document.getElementById("feeds");
  if (!gallery) return;
  const aspectRatio = 16 / 9;
  const screenWidth = gallery.clientWidth;
  const screenHeight = gallery.clientHeight;
  const videoCount = document.getElementsByTagName("video").length;

  // or use this nice lib: https://github.com/fzembow/rect-scaler
  function calculateLayout(
    containerWidth,
    containerHeight,
    videoCount,
    aspectRatio,
  ) {
    let bestLayout = {
      area: 0,
      cols: 0,
      rows: 0,
      width: 0,
      height: 0,
    };

    // brute-force search layout where video occupy the largest area of the container
    for (let cols = 1; cols <= videoCount; cols++) {
      const rows = Math.ceil(videoCount / cols);
      const hScale = containerWidth / (cols * aspectRatio);
      const vScale = containerHeight / rows;
      let width;
      let height;
      if (hScale <= vScale) {
        width = Math.floor(containerWidth / cols);
        height = Math.floor(width / aspectRatio);
      } else {
        height = Math.floor(containerHeight / rows);
        width = Math.floor(height * aspectRatio);
      }
      const area = width * height;
      if (area > bestLayout.area) {
        bestLayout = {
          area,
          width,
          height,
          rows,
          cols,
        };
      }
    }
    return bestLayout;
  }

  const { width, height, cols } = calculateLayout(
    screenWidth,
    screenHeight,
    videoCount,
    aspectRatio,
  );

  gallery.style.setProperty("--width", width + "px");
  gallery.style.setProperty("--height", height + "px");
  gallery.style.setProperty("--cols", cols + "");
}

export function Call() {
  const [showFullscreen, setShowFullscreen] = useState(true);
  const [callState, setCallState] = useState(initialState);
  const [call, setCall] = useState(null);

  useEffect(() => {
    const client = new SharedMatrix().getClient();
    client.on("GroupCall.incoming", (call) => {
      console.error("incomming");
      setCall(call);
    });
    client.on("GroupCall.outgoing", (call) => {
      console.error("outgoing");
      setCall(call);
    });
    client.on("GroupCall.participants", (participants, call) => {
      console.error("participants");
      setCall(call);
    });
    client.on("GroupCall.ended", (call) => {
      console.error("ended");
      setCall(call);
    });
    return () => {};
  }, []);

  useEffect(() => {
    window.addEventListener("resize", recalculateLayout);
    return () => {};
  }, []);

  useEffect(() => {
    recalculateLayout();
    return () => {};
  }, [callState, showFullscreen, call]);

  useEffect(() => {
    if (!call) return;

    const onCallChange = () => {
      setCallState({
        feeds: call.userMediaFeeds,
        state: call.state,
        participants: call.participants,
        activeSpeaker: call.activeSpeaker,
        isMicrophoneMuted: call.isMicrophoneMuted(),
        isLocalVideoMuted: call.isLocalVideoMuted(),
      });
    };

    call.on("user_media_feeds_changed", onCallChange);
    call.on("active_speaker_changed", onCallChange);
    call.on("screenshare_feeds_changed", onCallChange);
    call.on("local_screenshare_state_changed", onCallChange);
    call.on("calls_changed", onCallChange);
    call.on("participants_changed", onCallChange);
    call.on("local_mute_state_changed", onCallChange);
    call.on("local_screenshare_state_changed", onCallChange);
    call.on("group_call_state_changed", onCallChange);

    call.on("group_call_error", (error) => {
      alert(error);
    });
    return () => {
      // TODO remove listeners
    };
  }, [call]);

  if (!call) return null;

  const micMuted = call.isMicrophoneMuted();
  const vidMuted = call.isLocalVideoMuted();

  const voice = call.type === "m.voice";

  const remoteFeeds = callState.feeds
    // .filter((feed) => !feed.isLocal())
    .map((feed) => {
      if (voice) return React.createElement(Audio, { feed: feed });
      return React.createElement(Video, { feed: feed });
    });

  const enter = async () => {
    await call.enter();
    fullscreen();
  };

  const hangup = async () => {
    await call.terminate();
  };

  let element = null;

  const fullscreen = () => {
    setShowFullscreen(true);
  };

  const minimize = () => {
    setShowFullscreen(false);
  };

  const hangupElement = React.createElement(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 384 512",
      className: "cursor-pointer padding-right-16",
      onClick: hangup,
    },
    React.createElement("path", {
      d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
    }),
  );

  let fullscreenAvailable = false;
  switch (call.state) {
    case "entered":
      fullscreenAvailable = true;
      element = React.createElement(
        React.Fragment,
        null,
        React.createElement(
          "div",
          null,
          call.room.name +
            ": " +
            Array.from(call.participants)
              .map(([key, value]) => {
                return key.name;
              })
              .join(", "),
        ),
        React.createElement("div", { className: "flex-1-0-0" }),
        hangupElement,
      );
      break;
    case "initializing_local_call_feed":
    case "local_call_feed_initialized":
      element = React.createElement("div", null, "Anruf wird angenommen...");
      break;
    case "ended":
      return null;
      element = React.createElement("div", null, "Anruf beendet.");
      break;
    case "local_call_feed_uninitialized":
      element = React.createElement(
        React.Fragment,
        null,
        call.room.name +
          ": " +
          Array.from(call.participants)
            .map(([key, value]) => {
              return key.name;
            })
            .join(", "),
        React.createElement("div", { className: "flex-1-0-0" }),
        React.createElement(
          "button",
          { onClick: enter, className: "background-green" },
          "beitreten",
        ),
      );
      break;
    default:
      element = React.createElement(
        "div",
        null,
        "Unbekannter Status: ",
        call.state,
      );
  }

  fullscreenAvailable &&= !voice;

  const toggleMute = () => {
    call.setMicrophoneMuted(!micMuted);
  };

  const toggleVid = () => {
    call.setLocalVideoMuted(!vidMuted);
  };

  if (showFullscreen && fullscreenAvailable) {
    return React.createElement(
      "div",
      { className: "call full", id: "full" },
      React.createElement(
        "div",
        {
          className: "header",
        },
        React.createElement("h1", {}, call.room.name),
        React.createElement(
          "svg",
          {
            className: "cursor-pointer",
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 512 512",
            onClick: minimize,
          },
          React.createElement("path", {
            d: "M439 7c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H296c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39L439 7zM72 272H216c13.3 0 24 10.7 24 24V440c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39L73 505c-9.4 9.4-24.6 9.4-33.9 0L7 473c-9.4-9.4-9.4-24.6 0-33.9l87-87L55 313c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8z",
          }),
        ),
      ),
      React.createElement(
        "div",
        {
          className: "feeds",
          id: "feeds",
        },
        remoteFeeds,
      ),
      React.createElement(
        "div",
        {
          className: "controls",
        },
        React.createElement(
          "svg",
          {
            className:
              "cursor-pointer padding-6 border-radius-16 " +
              (micMuted && "background-red"),
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 640 512",
            onClick: toggleMute,
          },
          React.createElement("path", {
            d: "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L472.1 344.7c15.2-26 23.9-56.3 23.9-88.7V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 21.2-5.1 41.1-14.2 58.7L416 300.8V96c0-53-43-96-96-96s-96 43-96 96v54.3L38.8 5.1zm362.5 407l-43.1-33.9C346.1 382 333.3 384 320 384c-70.7 0-128-57.3-128-128v-8.7L144.7 210c-.5 1.9-.7 3.9-.7 6v40c0 89.1 66.2 162.7 152 174.4V464H248c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H344V430.4c20.4-2.8 39.7-9.1 57.3-18.2z",
          }),
        ),
        React.createElement(
          "svg",
          {
            className:
              "cursor-pointer padding-6 border-radius-16 " +
              (vidMuted && "background-red"),
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 640 512",
            onClick: toggleVid,
          },
          React.createElement("path", {
            d: "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-86.4-67.7 13.8 9.2c9.8 6.5 22.4 7.2 32.9 1.6s16.9-16.4 16.9-28.2V128c0-11.8-6.5-22.6-16.9-28.2s-23-5-32.9 1.6l-96 64L448 174.9V192 320v5.8l-32-25.1V128c0-35.3-28.7-64-64-64H113.9L38.8 5.1zM407 416.7L32.3 121.5c-.2 2.1-.3 4.3-.3 6.5V384c0 35.3 28.7 64 64 64H352c23.4 0 43.9-12.6 55-31.3z",
          }),
        ),
        hangupElement,
      ),
    );
  }

  return React.createElement(
    "div",
    { className: "call flex align-items-center fill-white" },
    React.createElement(
      "div",
      { className: "padding-right-16" },
      voice ? React.createElement(AudioIcon) : React.createElement(VideoIcon),
    ),
    element,
    remoteFeeds,
    fullscreenAvailable &&
      React.createElement(
        "svg",
        {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 512 512",
          className: "cursor-pointer",
          onClick: fullscreen,
        },
        React.createElement("path", {
          d: "M344 0H488c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-39-39-87 87c-9.4 9.4-24.6 9.4-33.9 0l-32-32c-9.4-9.4-9.4-24.6 0-33.9l87-87L327 41c-6.9-6.9-8.9-17.2-5.2-26.2S334.3 0 344 0zM168 512H24c-13.3 0-24-10.7-24-24V344c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l39 39 87-87c9.4-9.4 24.6-9.4 33.9 0l32 32c9.4 9.4 9.4 24.6 0 33.9l-87 87 39 39c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8z",
        }),
      ),
  );
}
