import { Controller } from "@hotwired/stimulus";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import React from "react";
import { createRoot } from "react-dom/client";
import { Matrix } from "../components/Matrix";
export default class extends Controller {
  root = false;

  initialize() {
    const token = this.data.get("token");
    const homeserverUrl = this.data.get("homeserverurl");
    const matrixId = this.data.get("matrixid");
    const matrix = new SharedMatrix().getMatrix();
    matrix.setToken(token);
    matrix.setHomeserverUrl(homeserverUrl);
    matrix.setMatrixUserId(matrixId);
    this.root = createRoot(this.element);
    this.root.render(React.createElement(Matrix, null, null));
  }
}
