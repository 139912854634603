import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";
import React from "react";

import { BlockUnlessMatrixRunning } from "../components/BlockUnlessMatrixRunning";

export default class extends Controller {
  root = false;

  connect() {
    this.root = createRoot(this.element);
    this.root.render(React.createElement(BlockUnlessMatrixRunning, null, null));
  }

  disconnect() {
    this.root?.unmount();
  }
}
