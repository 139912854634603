import React, { useEffect, useState } from "react";
import eventToUrl from "../../utils/encryptedFileToUrl";
import { Download } from "../icons/Download";

export function File({ event }) {
  const [url, setUrl] = useState(null);
  const content = event.getContent();

  const loadImage = async () => {
    const url = await eventToUrl(event);
    setUrl(url);
  };

  useEffect(() => {
    loadImage();
  }, [event]);

  if (url) {
    return React.createElement(
      "div",
      {
        className: "cursor-pointer flex align-items-center gap-12",
        onClick: () => {
          const link = document.createElement("a");
          link.href = url;
          link.download = content.body;
          link.click();
        },
      },
      content.body,
      React.createElement(Download),
    );
  }

  return React.createElement(
    "div",
    {},
    "Datei wird geladen und entschlüsselt...",
  );
}
