import React, { useEffect, useState } from "react";
import eventToUrl from "../../utils/encryptedFileToUrl";

export function Audio({ event }) {
  const [url, setUrl] = useState(null);
  const loadAudio = async () => {
    setUrl(await eventToUrl(event));
  };

  useEffect(() => {
    loadAudio();
  }, [event]);

  if (url) {
    return React.createElement("audio", {
      src: url,
      autoPlay: false,
      controls: true,
      preload: "auto",
    });
  }

  return React.createElement(
    "div",
    {},
    "Sprachnachricht wird geladen und entschlüsselt...",
  );
}
