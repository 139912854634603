import React from "react";

export function Loader() {
  return React.createElement("div", {
    style: {
      border: "6px solid #f3f3f3",
      borderTop: "6px solid var(--primary-color)",
      borderRadius: "50%",
      width: "20px",
      height: "20px",
      animation: "spin 2s linear infinite",
      flexShrink: 0,
    },
  });
}
