import * as sdk from "matrix-js-sdk";

// TODO: remove getSecretStorageKey (unused)
const getSecretStorageKey = async ({ keys: keyInfos }) => {
  const inputPassphrase = window.mks_mx_matrix.key;
  if (!inputPassphrase) return null;

  let keyId = await window.mks_mx_matrix.client.getDefaultSecretStorageKeyId();
  let keyInfo;

  if (keyId) {
    keyInfo = keyInfos[keyId];
    if (!keyInfo) {
      keyId = null;
    }
  }

  if (!keyId) {
    const keyInfoEntries = Object.entries(keyInfos);
    if (keyInfoEntries.length > 1) {
      throw new Error("Multiple storage key requests not implemented");
    }
    [keyId, keyInfo] = keyInfoEntries[0];
  }

  if (!keyInfo) return null;

  return [
    keyId,
    await window.mks_mx_matrix.client.keyBackupKeyFromRecoveryKey(
      inputPassphrase,
    ),
  ];
};

function newStore() {
  const opts = {
    indexedDB: window.indexedDB,
    localStorage: window.localStorage,
  };
  return new sdk.IndexedDBStore(opts);
}

function clearAll() {
  localStorage.removeItem("mx_device_id");
  localStorage.removeItem("mx_user_id");
  localStorage.removeItem("mx_access_token");
  sdk
    .createClient({
      store: newStore(),
    })
    .clearStores();
}

function getStoredSession() {
  return {
    storedDeviceId: localStorage.getItem("mx_device_id"),
    storedMatrixId: localStorage.getItem("mx_user_id"),
    storedAccessToken: localStorage.getItem("mx_access_token"),
  };
}

function storeSession(deviceId, matrixId, accessToken) {
  localStorage.setItem("mx_device_id", deviceId);
  localStorage.setItem("mx_user_id", matrixId);
  localStorage.setItem("mx_access_token", accessToken);
}

export async function initClient(
  homeserverUrl,
  matrixId,
  token,
  cryptoCallbacks,
) {
  if (matrixId !== localStorage.getItem("mx_user_id")) {
    clearAll();
  }
  let { storedDeviceId, storedMatrixId, storedAccessToken } =
    getStoredSession();

  if (!(storedDeviceId && storedMatrixId && storedAccessToken)) {
    const newLogin = await login(homeserverUrl, token);
    storedDeviceId = newLogin.deviceId;
    storedMatrixId = newLogin.matrixId;
    storedAccessToken = newLogin.accessToken;
    storeSession(storedDeviceId, storedMatrixId, storedAccessToken);
  }

  return sdk.createClient({
    // store: newStore(), // TODO: throws error
    useE2eForGroupCall: false,
    baseUrl: homeserverUrl,
    accessToken: storedAccessToken,
    userId: storedMatrixId,
    deviceId: storedDeviceId,
    cryptoCallbacks: cryptoCallbacks,
  });
}

async function login(homeserverUrl, token) {
  const client = sdk.createClient({ baseUrl: homeserverUrl });
  const response = await client.login("org.matrix.login.jwt", {
    type: "org.matrix.login.jwt",
    token: token,
  });

  return {
    deviceId: response.device_id,
    matrixId: response.user_id,
    accessToken: response.access_token,
  };
}

// TODO: remove MatrixSession (unused)
export class MatrixSession {
  client = null;
  accessToken = null;
  userId = null;
  deviceId = null;
  newLogin = false;
  rehydrated = false;
  homeserverUrl = null;

  init = async (token, homeserverUrl) => {
    this.homeserverUrl = homeserverUrl;
    await this.#loadStoredSession();

    if (!(this.deviceId && this.userId && this.accessToken)) {
      this.newLogin = true;
      await login(token);
    }

    // if (!this.client) await this.#createClient();

    const client = this.client;
    await client.store.startup();

    this.client.on("RoomMember.membership", function (event, member) {
      if (
        member.membership === "invite" &&
        member.userId === client.getUserId()
      ) {
        client.joinRoom(member.roomId).then(function () {
          console.log("Auto-joined %s", member.roomId);
        });
      }
    });

    if (this.newLogin) {
      const dehydratedDevice = await this.client.getDehydratedDevice();
      if (dehydratedDevice) return "rehydrate";
    }

    try {
      await this.client.initRustCrypto();
    } catch (error) {
      console.warn(error);
      try {
        await this.client.clearStores();
        await this.client.initRustCrypto();
      } catch (error) {
        console.error(error);
        return { info: "Crypto.InitFailed", client: this.client };
      }
    }

    this.client.setGlobalErrorOnUnknownDevices(false);
    await this.client.startClient({
      initialSyncLimit: 5,
    });

    const crypto = this.client.getCrypto();

    const backupInfo = await this.client.getKeyBackupVersion();
    if (!backupInfo) return { info: "Setup", client: this.client };

    const isCrossSigningReady = await crypto.isCrossSigningReady();
    if (!isCrossSigningReady) return { info: "Backup", client: this.client };

    return { info: "Ready", client: this.client };
  };

  #loadStoredSession = async () => {
    this.deviceId = localStorage.getItem("mx_device_id");
    this.userId = localStorage.getItem("mx_user_id");
    this.accessToken = localStorage.getItem("mx_access_token");
  };

  #storeSession = async () => {
    localStorage.setItem("mx_device_id", this.deviceId);
    localStorage.setItem("mx_user_id", this.userId);
    localStorage.setItem("mx_access_token", this.accessToken);
  };

  #clearSession = async () => {};
}
