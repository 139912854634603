export const request = async (path, method, body = null) => {
  if (body) body = JSON.stringify(body);
  const response = await fetch(path, {
    method: method,
    body: body,
    headers: {
      "X-CSRF-Token": getCSRFToken(),
      "Content-Type": "application/json",
    },
  });
  return await response.json();
};

const getCSRFToken = function () {
  if (window.CSRF_Token) return window.CSRF_Token;
  for (const metaTag of document.getElementsByTagName("meta")) {
    if (metaTag.getAttribute("name") === "csrf-token") {
      return metaTag.getAttribute("content");
    }
  }
  return null;
};
