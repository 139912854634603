import React, { useEffect, useState } from "react";
import * as sdk from "matrix-js-sdk";
import { request } from "../utils/request";
import { SharedMatrix } from "../mks-matrix/shared-matrix";

export function RoomName({ room }) {
  const [name, setName] = useState(room.name);

  const getMemberNames = async () => {
    const client = new SharedMatrix().getClient();
    const membs = room
      .getMembers()
      .filter((m) => client.getUserId() !== m.userId);
    if (!membs[0]) return;

    const response = await request(
      `/contacts/from_matrix?matrix_id=${membs[0].userId}`,
      "GET",
    );
    setName(response.display_name);
  };

  useEffect(() => {
    const nameState = room
      .getLiveTimeline()
      .getState(sdk.EventTimeline.FORWARDS)
      ?.getStateEvents("m.room.name", "");

    if (!nameState) getMemberNames();
  }, [room]);

  return React.createElement(React.Fragment, {}, name);
}
