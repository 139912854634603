import React from "react";

export function Modal({ children, title, close }) {
  return React.createElement(
    "div",
    { className: "modal" },
    React.createElement(
      "div",
      { className: "modal-content border-radius-16" },
      React.createElement(
        "div",
        { className: "flex" },
        React.createElement("h3", {}, title),
        React.createElement("div", { className: "flex-1-0-0" }),
        // TODO: use icon
        React.createElement(
          "h3",
          { className: "cursor-pointer", onClick: close },
          "X",
        ),
      ),
      React.createElement("hr"),
      children,
    ),
  );
}
