import React, { useState, useEffect } from "react";
import { request } from "../utils/request";

export function DisplayName({ user }) {
  const [displayName, setDisplayname] = useState(user.displayName);
  const fetchUser = async () => {
    const response = await request(
      `/contacts/from_matrix?matrix_id=${user.userId}`,
      "GET",
    );
    setDisplayname(response.display_name);
  };
  useEffect(() => {
    fetchUser();
  });
  return React.createElement(React.Fragment, {}, displayName);
}
