import React, { Fragment, useEffect, useState } from "react";
import { SharedMatrix } from "../../mks-matrix/shared-matrix";
import { request } from "../../utils/request";
import { Loader } from "../Loader";

const userRequiresOtp = async (setRequired) => {
  const client = new SharedMatrix().getClient();
  if (!(await client.getKeyBackupVersion())) {
    tryToUpload();
    return;
  }
  const response = await request("/users/two_fa_enabled", "GET");
  setRequired(response.enabled);
};

export function Credentials({ makeRequest, credentialsPromise }) {
  const client = new SharedMatrix().getClient();
  const [state, setState] = useState({
    password: "",
    otp: "",
    error: null,
  });

  const [required, setRequired] = useState(null);

  const tryToUpload = async () => {
    const authDict = {
      type: "de.monks.password",
      password: state.password,
      otp: state.otp,
      identifier: {
        type: "m.id.user",
        user: client.getUserId(),
      },
    };

    const response = await makeRequest(authDict).catch(() => {
      setState({ ...state, error: "ungültig" });
    });

    if (response) credentialsPromise();
  };
  useEffect(() => {
    userRequiresOtp(setRequired, tryToUpload);
  }, []);

  if (required == null) return <Loader></Loader>;

  return (
    <Fragment>
      {state.error ? <p>{state.error}</p> : null}
      <div>
        <label>
          Password
          <br />
          <input
            type="password"
            className="border"
            value={state.password}
            onChange={(event) =>
              setState({ ...state, password: event.target.value })
            }
          />
        </label>
      </div>
      {required ? (
        <div>
          <label>
            OTP
            <br />
            <input
              className="border"
              value={state.otp}
              onChange={(event) =>
                setState({ ...state, otp: event.target.value })
              }
            />
          </label>
        </div>
      ) : null}
      <button onClick={tryToUpload}>Send</button>
    </Fragment>
  );
}
