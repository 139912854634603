import React, { Fragment, useEffect, useState } from "react";
import { SharedMatrix } from "../../mks-matrix/shared-matrix";
import { PollResponseEvent } from "matrix-js-sdk/lib/extensible_events_v1/PollResponseEvent";

export function Poll({ poll, sourceEvent }) {
  const [state, setState] = useState({ relations: [] });

  const getResponses = async () => {
    const r = await poll.getResponses();
    setState({ relations: r.relations });
  };

  const client = new SharedMatrix().getClient();
  let myVote = null;
  const votes = [];
  if (state.relations.size > 0) {
    const responses = Object.groupBy(state.relations, (a) => a.sender.userId);
    for (const [key, value] of Object.entries(responses)) {
      votes.push(value[0]);
      if (key === client.getUserId()) {
        myVote = value[0];
      }
    }
  }

  useEffect(() => {
    getResponses();
  }, [poll, sourceEvent]);

  const createAnswer = async (id, checked) => {
    const previousAnswers =
      myVote?.getContent()["org.matrix.msc3381.poll.response"].answers || [];
    const answers = checked
      ? [...previousAnswers, id]
      : previousAnswers.filter((e) => e != id);
    const reponseEvent = PollResponseEvent.from(
      answers,
      sourceEvent.getId(),
    ).serialize();
    client.sendEvent(
      sourceEvent.getRoomId(),
      reponseEvent.type,
      reponseEvent.content,
    );
  };

  return (
    <Fragment>
      <b>{poll.pollEvent.question.text}</b>
      {poll.pollEvent.answers.map((a) => {
        const count = votes.filter((v) =>
          v
            .getContent()
            ["org.matrix.msc3381.poll.response"]?.answers?.includes(a.id),
        ).length;
        return (
          <div>
            <input
              type="checkbox"
              checked={
                !!myVote
                  ?.getContent()
                  ["org.matrix.msc3381.poll.response"]?.answers?.includes(a.id)
              }
              onChange={(e) => {
                createAnswer(a.id, e.target.checked);
              }}
            />{" "}
            {a.text} <progress value={count} max={votes.length}></progress>
          </div>
        );
      })}
    </Fragment>
  );
}
