import React, { useRef, useEffect } from "react";

export function Video({ feed }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && feed?.stream) {
      videoRef.current.srcObject = feed.stream;
    }
  }, [feed.stream, feed.speaking]);
  return React.createElement(
    "div",
    { className: "video-container" },
    React.createElement("video", {
      ref: videoRef,
      autoPlay: true,
      muted: feed.isLocal(),
      controls: false,
      playsInline: true,
      className: `border-radius-16 ${feed.speaking && "border-3 border-color-orange"} ${feed.isLocal() && "mirrored"}`,
    }),
  );
}
