import React, { useState, useEffect } from "react";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import { request } from "../utils/request";
import { ContactInfo } from "./ContactInfo";

const initialState = {
  roomName: "",
  contacts: [],
  roomError: null,
  contactsError: null,
  filters: [],
};
export function CreateRoom({ close }) {
  const [state, setState] = useState(initialState);

  const fetchContacts = async () => {
    const availableFilters = [];
    const response = await request("/contacts", "GET").catch(() => {
      setState({
        ...state,
        contactsError: "Fehler beim Abrufen der Kontakte.",
      });
      return;
    });
    if (Array.isArray(response)) {
      if (!!response.filter((c) => c.external).length)
        availableFilters.push({
          func: (x) => x.external,
          name: "Leistunserbringer",
          background: "background-orange",
        });
      if (!!response.filter((c) => c.guest).length)
        availableFilters.push({
          func: (x) => x.guest,
          name: "Familienmitglieder",
          background: "background-guest",
        });
      [
        ...new Set(
          response.filter((c) => c.internal).map((c) => c.facility_name),
        ),
      ].forEach((facilityName) => {
        availableFilters.push({
          func: (x) => x.internal && x.facility_name === facilityName,
          name: facilityName,
          background: "background-primary",
        });
      });
      setState({ ...state, contacts: response, filters: availableFilters });
    } else {
      setState({
        ...state,
        contactsError: "Fehler beim Abrufen der Kontakte.",
      });
    }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const toggleContactSelected = (contact) => {
    const copy = { ...contact };
    if (copy.selected) {
      copy.selected = false;
    } else {
      copy.selected = true;
    }
    setState({
      ...state,
      contacts: state.contacts.filter((c) => c !== contact).concat([copy]),
    });
  };

  const createRoomAndInviteUsers = async () => {
    if (!state.roomName) {
      setState({ ...state, roomError: "Gruppenname ist erforderlich." });
      return;
    }

    let type = "internal";
    if (!!state.contacts.filter((c) => c.selected && c.guest).length)
      type = "guest";
    else if (!!state.contacts.filter((c) => c.selected && c.external).length)
      type = "external";

    const client = new SharedMatrix().getClient();
    const room = await client
      .createRoom({
        visibility: "private",
        name: state.roomName,
        invite: state.contacts
          .filter((c) => c.selected)
          .map((c) => c.matrix_id),
        initial_state: [
          {
            type: "m.room.encryption",
            state_key: "",
            content: {
              algorithm: "m.megolm.v1.aes-sha2",
            },
          },
          {
            type: "de.mein-jugendamt.tags",
            state_key: "",
            content: {
              type: type,
            },
          },
        ],
        power_level_content_override: {
          invite: 100,
          kick: 100,
          ban: 100,
          redact: 50,
          state_default: 0,
          events_default: 0,
          users_default: 0,
          events: {
            "m.room.power_levels": 100,
            "de.mein-jugendamt.tags": 100,
            "m.room.history_visibility": 100,
            "m.room.tombstone": 100,
            "m.room.encryption": 100,
            "m.room.name": 50,
            "m.room.message": 0,
            "m.room.encrypted": 0,
            "m.sticker": 50,
            "org.matrix.msc3401.call.member": 0,
          },
        },
      })
      .catch(() => {
        setState({
          ...state,
          roomError: "Fehler beim Erstellen des Gruppenchats.",
        });
      });
    if (room) close(room.room_id);
  };

  const sortedContacts = state.contacts
    .filter(
      (c) =>
        c.selected ||
        !state.filters.filter((f) => f.active).length ||
        !!state.filters.filter((f) => f.active && f.func(c)).length,
    )
    .toSorted((a, b) => {
      const aSelected = a.selected;
      const bSelected = b.selected;
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });

  const contactElements = sortedContacts.map((contact) =>
    React.createElement(
      "div",
      {
        className:
          "contact-item border-top padding-top-bottom-12 flex align-items-center justify-content-between",
      },
      React.createElement(ContactInfo, { contact: contact }),
      React.createElement(
        "div",
        {
          className: "flex align-items-center move-to-end cursor-pointer",
        },
        React.createElement(
          "button",
          {
            className: `padding-6 fill-white border-radius-6 ${
              contact.selected ? "background-red" : "background-primary"
            }`,
            onClick: () => toggleContactSelected(contact),
          },
          React.createElement(
            "div",
            { className: "cursor-pointer move-to-end" },
            contact.selected ? "-" : "+",
          ),
        ),
      ),
    ),
  );

  return React.createElement(
    "div",
    { className: "main active" },
    React.createElement(
      "div",
      { className: "flex align-items-center" },
      React.createElement("h1", null, "Gruppenchat erstellen"),
      React.createElement(
        "div",
        { onClick: close, className: "cursor-pointer move-to-end" },
        React.createElement(
          "svg",
          {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 384 512",
          },
          React.createElement("path", {
            d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
          }),
        ),
      ),
    ),
    React.createElement("hr", { className: "border-top" }),
    React.createElement(
      "button",
      {
        className: "input-submit padding-8-16",
        onClick: createRoomAndInviteUsers,
      },
      "Chat erstellen",
    ),
    React.createElement("h2", null, "Informationen"),
    React.createElement("hr", { className: "border-top" }),
    React.createElement(
      "div",
      { className: "flex flex-direction-column margin-10-0" },
      React.createElement(
        "div",
        { className: "flex align-items-center" },
        React.createElement("input", {
          placeholder: "Gruppenname",
          value: state.roomName,
          onChange: (event) =>
            setState({ ...state, roomName: event.target.value }),
          className:
            "input border border-color-grey margin-10-0 margin-right-8",
        }),
        state.roomError &&
          React.createElement(
            "div",
            { className: "flex align-items-center" },
            React.createElement(
              "p",
              { className: "color-red margin-10-0" },
              state.roomError,
            ),
          ),
      ),
    ),
    React.createElement("h2", null, "Kontakte"),
    React.createElement(
      "div",
      { className: "flex" },
      state.filters.map((f) =>
        React.createElement(
          "div",
          {
            onClick: () =>
              setState({
                ...state,
                filters: state.filters.map((filter) =>
                  filter !== f ? filter : { ...f, active: !f.active },
                ),
              }),
            className: `cursor-pointer border-radius-16 padding-4-8 border border-width-2 ${f.background} ${f.active ? "" : "border-color-white"}`,
          },
          f.name,
        ),
      ),
    ),
    state.contactsError &&
      React.createElement("p", { className: "color-red" }, state.contactsError),
    React.createElement("div", { className: "contacts-list" }, contactElements),
  );
}
