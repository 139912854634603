import React, { useEffect, useState } from "react";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import { request } from "../utils/request";

export function Avatar({ userId }) {
  const [url, setUrl] = useState(null);
  const [initials, setInitials] = useState("");
  const client = new SharedMatrix().getClient();
  const presence = client.getUser(userId)?.presence;

  const getAvatar = async () => {
    const info = await client.getProfileInfo(userId);
    if (info.avatar_url) {
      setUrl(client.mxcUrlToHttp(info.avatar_url));
    }
  };

  useEffect(() => {
    getAvatar();
  });

  const fetchUser = async () => {
    const response = await request(
      `/contacts/from_matrix?matrix_id=${userId}`,
      "GET",
    );
    setInitials(response.initials);
  };
  useEffect(() => {
    fetchUser();
  });

  if (!!url)
    return React.createElement(
      "div",
      {
        style: {
          height: "40px",
          width: "40px",
        },
      },
      React.createElement("img", {
        src: url,
        style: {
          borderRadius: "20px",
          height: "40px",
          width: "40px",
          aspectRatio: "1/1",
          objectFit: "cover",
          flexShrink: 0,
        },
      }),
      presence === "online" &&
        React.createElement("div", {
          style: {
            position: "relative",
            height: "14px",
            width: "14px",
            left: "28px",
            top: "-16px",
            borderRadius: "7px",
            backgroundColor: "green",
            color: "green",
          },
        }),
    );

  return React.createElement(
    "div",
    {
      style: {
        height: "40px",
        width: "40px",
      },
    },
    React.createElement(
      "div",
      {
        className: "background-primary",
        style: {
          borderRadius: "20px",
          height: "40px",
          width: "40px",
          textAlign: "center",
          lineHeight: "40px",
          flexShrink: 0,
        },
      },
      React.createElement("b", {}, initials),
    ),
    presence === "online" &&
      React.createElement("div", {
        style: {
          position: "relative",
          height: "14px",
          width: "14px",
          left: "28px",
          top: "-14px",
          borderRadius: "7px",
          backgroundColor: "green",
          color: "green",
        },
      }),
  );
}
