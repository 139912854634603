import React from "react";
import { useState, useEffect } from "react";
import { Sidebar } from "./Sidebar";
import { CreateRoom } from "./CreateRoom";
import { Room } from "./Room";
import { SharedMatrix } from "../mks-matrix/shared-matrix";

const initialState = {
  rooms: [],
  selectedRoom: null,
  showCreateRoom: false,
};

const compareRooms = (a, b) => {
  const getLastLocalTimestamp = (room) => {
    return room.timeline && room.timeline.length > 0
      ? room.timeline[room.timeline.length - 1]?.localTimestamp
      : 0;
  };
  const lastLocalTimestampA = getLastLocalTimestamp(a);
  const lastLocalTimestampB = getLastLocalTimestamp(b);
  if (lastLocalTimestampA < lastLocalTimestampB) return 1;
  return -1;
};

const showMenu = () => {
  Array.from(document.getElementsByClassName("menu")).forEach((element) => {
    element.classList.add("active");
  });
  Array.from(document.getElementsByClassName("sidebar")).forEach((element) => {
    element.classList.add("active");
  });
  Array.from(document.getElementsByClassName("encryption")).forEach(
    (element) => {
      element.classList.add("active");
    },
  );
  Array.from(document.getElementsByClassName("app")).forEach((element) => {
    element.classList.remove("active");
  });
};

const showMain = () => {
  Array.from(document.getElementsByClassName("menu")).forEach((element) => {
    element.classList.remove("active");
  });
  Array.from(document.getElementsByClassName("sidebar")).forEach((element) => {
    element.classList.remove("active");
  });
  Array.from(document.getElementsByClassName("encryption")).forEach(
    (element) => {
      element.classList.remove("active");
    },
  );
  Array.from(document.getElementsByClassName("app")).forEach((element) => {
    element.classList.add("active");
  });
};

export function Chat() {
  const [state, setState] = useState(initialState);
  const client = new SharedMatrix().getClient();

  useEffect(() => {
    setState({ ...state, rooms: client.getRooms().sort(compareRooms) });
    return () => {
      setState({ ...state, rooms: [] });
    };
  }, []);

  useEffect(() => {
    const list = [
      "Room.myMembership",
      "Room.tags",
      "Room.accountData",
      "Room.receipt",
      "Room.name",
      "Room.redaction",
      "Room.redactionCancelled",
      "Room.localEchoUpdated",
      "Room.timeline",
      "Room.timelineReset",
      "Room.TimelineRefresh",
      "Room.OldStateUpdated",
      "Room.CurrentStateUpdated",
      "Room.historyImportedWithinTimeline",
      "Room.UnreadNotifications",
      "Room.Summary",
      "Event.decrypted",
      "Event.beforeRedaction",
      "Event.visibilityChange",
      "Event.localEventIdReplaced",
      "Event.status",
      "Event.replaced",
      "Event.relationsCreated",
    ];

    list.forEach((element) => {
      client.on(element, () => {
        setState((previousSate) => {
          return {
            ...previousSate,
            rooms: client.getRooms().sort(compareRooms),
            selectedRoom: client.getRoom(previousSate.selectedRoom?.roomId),
          };
        });
      });
    });

    return () => {};
  }, []);

  const selectRoom = (room) => {
    showMain();
    setState({ ...state, selectedRoom: room, showCreateRoom: false });
  };

  const unselect = () => {
    showMenu();
    setState({ ...state, selectedRoom: null, showCreateRoom: false });
  };

  const closeCreateRoomDialog = (roomId) => {
    setState({
      ...state,
      showCreateRoom: false,
      selectedRoom: client.getRoom(roomId),
    });
  };

  const openCreateRoomDialog = () => {
    showMain();
    setState({ ...state, showCreateRoom: true, selectedRoom: null });
  };

  return React.createElement(
    React.Fragment,
    null,
    React.createElement(Sidebar, {
      rooms: state.rooms,
      selectRoom: selectRoom,
      plus: openCreateRoomDialog,
      selectedRoom: state.selectedRoom,
    }),
    state.showCreateRoom
      ? React.createElement(CreateRoom, { close: closeCreateRoomDialog })
      : React.createElement(
          React.Fragment,
          null,
          !state.selectedRoom
            ? React.createElement(
                "div",
                { className: "main" },
                "Kein Chat ausgewählt.",
              )
            : React.createElement(Room, {
                room: state.selectedRoom,
                unselect: unselect,
                key: state.selectedRoom.roomId,
              }),
        ),
  );
}
