import { Controller } from "@hotwired/stimulus";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import { createRoot } from "react-dom/client";
import { AvatarSetting } from "../components/AvatarSetting";
import React from "react";

export default class extends Controller {
  root = false;

  setup = () => {
    if (!!this.root) return;
    this.root = createRoot(this.element);
    this.root.render(React.createElement(AvatarSetting));
  };

  connect() {
    const matrix = new SharedMatrix().getMatrix();
    matrix.onRunning(this.setup);
    if (matrix.running()) this.setup();
  }

  disconnect() {
    this.root?.unmount();
    this.root = false;
  }
}
