// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./controllers";
import { SharedMatrix } from "./mks-matrix/shared-matrix";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then((rs) => {
    rs.forEach((r) => r.update());
  });

  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/service-worker.js").then(
      function (registration) {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope,
        );
      },
      function (error) {
        console.error("ServiceWorker registration failed: ", error);
      },
    );
  });
}

const logoutChannel = new BroadcastChannel("logout_channel");
const logoutRedirect = (event) => {
  if (event.data === "LOGOUT") {
    window.location.reload();
  } else if (event.data === "ANIMATION") {
    const div = document.createElement("div");
    const spinner = document.createElement("div");
    div.id = "loading-app";
    spinner.id = "loading-app-spinner";
    div.append(spinner);
    div.append("Sie werden ausgeloogt und Ihre Sitzungsdaten gelöscht.");
    document.body.prepend(div);
  }
};
logoutChannel.onmessage = logoutRedirect;
