import React, { useEffect, useState } from "react";
import eventToUrl from "../../utils/encryptedFileToUrl";

export function Image({ event }) {
  const [url, setUrl] = useState(null);
  const loadImage = async () => {
    const url = await eventToUrl(event);
    setUrl(url);
  };

  useEffect(() => {
    loadImage();
  }, [event]);

  if (url) {
    return React.createElement("img", {
      src: url,
      className: "max-width-100 max-height-300 border-radius-16",
    });
  }

  return React.createElement(
    "div",
    {},
    "Bild wird geladen und entschlüsselt...",
  );
}
