import { Controller } from "@hotwired/stimulus";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import React from "react";

export default class extends Controller {
  static targets = ["button"];
  showButton = () => {
    const matrix = new SharedMatrix().getMatrix();
    this.element.hidden = false;
  };

  setup = async () => {
    this.buttonTarget.innerHTML = "loading...";
    this.buttonTarget.disabled = true;
    const matrix = new SharedMatrix().getMatrix();
    if (await matrix.setupPush()) {
      this.buttonTarget.innerHTML = "erfolgreich.";
    } else {
      this.buttonTarget.innerHTML = "Fehler. Erneut versuchen";
      this.buttonTarget.disabled = false;
    }
  };

  connect() {
    const matrix = new SharedMatrix().getMatrix();
    matrix.onRunning(this.showButton);
    if (matrix.running()) this.showButton();
  }
}
