import { decryptAttachment } from "matrix-encrypt-attachment";
import { SharedMatrix } from "../mks-matrix/shared-matrix";

export default async function (event) {
  const content = event.getContent();
  const client = new SharedMatrix().getClient();
  const response = await fetch(client.mxcUrlToHttp(content.file.url));
  const encryptedBuffer = await response.arrayBuffer();
  const decryptedBuffer = await decryptAttachment(
    encryptedBuffer,
    content.file,
  );
  const blob = new Blob([new Uint8Array(decryptedBuffer)], {
    type: content.info.mimetype,
  });
  return URL.createObjectURL(blob);
}
