import React, { useEffect, useState } from "react";
import { DisplayName } from "./DisplayName";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import { Video } from "./icons/Video";
import { Phone } from "./icons/Phone";
import { Image } from "./events/Image";
import { File } from "./events/File";
import { Audio } from "./events/Audio";
import { Avatar } from "./Avatar";
import { Poll } from "matrix-js-sdk";
import { Poll as PollEvent } from "./events/Poll";

export function Message({
  room,
  event,
  events,
  showExport,
  markForExport,
  markedForExport,
}) {
  let type = 0; // 0: status, 1: message, 2: call, -1 hide
  let content = "UNKNOWN";
  let skipBox = false;
  const client = new SharedMatrix().getClient();
  const sender = client.getUser(event.getSender());
  const senderDisplayName = React.createElement(DisplayName, {
    user: sender,
  });
  let exportable = false;

  useEffect(() => {
    if (showExport && exportable && markedForExport === undefined)
      markForExport(event, content);
  }, [showExport, exportable, markedForExport]);

  const targetDisplayName = !!event.target
    ? React.createElement(DisplayName, { user: event.target })
    : null;
  const isCurrentUserSender = client.getUserId() === event.getSender();
  const eventContent = event.getContent();

  switch (event.getType()) {
    case "m.room.create":
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        " hat den Chat erstellt",
      );
      break;
    case "m.room.encryption":
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        " hat die Verschlüsselung aktiviert",
      );
      break;
    case "m.room.member":
      if (event.getContent().membership === "invite") {
        content = React.createElement(
          React.Fragment,
          {},
          senderDisplayName,
          " hat ",
          targetDisplayName,
          " eingeladen",
        );
      } else if (event.getContent().membership === "join") {
        return;
        content = React.createElement(
          React.Fragment,
          {},
          targetDisplayName,
          " ist beigetreten",
        );
      } else if (event.getContent().membership === "leave") {
        content = React.createElement(
          React.Fragment,
          {},
          targetDisplayName,
          " hat den Raum verlassen",
        );
      }
      break;
    case "m.room.power_levels":
      return;
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        " hat die Berechtigungen angepasst",
      );
      break;
    case "m.room.join_rules":
      return;
      const joinRule =
        event.getContent().join_rule === "invite" ? "Einladung" : "unbekannt";
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        ` hat die Beitrittsregeln auf ${joinRule} gesetzt`,
      );
      break;
    case "m.room.history_visibility":
      return;
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        " hat die Verlaufssichtbarkeit festgelegt",
      );
      break;
    case "m.room.guest_access":
      return;
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        " hat den Gastzugang angepasst",
      );
      break;
    case "m.room.message":
      type = 1;
      switch (eventContent.msgtype) {
        case "m.text":
          exportable = true;
          content = eventContent.body;
          break;
        case "m.bad.encrypted":
          content = "**Nachricht kann nicht entschlüsselt werden**";
          break;
        case "m.image":
          skipBox = true;
          content = React.createElement(Image, { event: event });
          break;
        case "m.file":
          content = React.createElement(File, { event: event });
          break;
        case "m.audio":
          skipBox = true;
          content = React.createElement(Audio, { event: event });
          break;
        default:
          content = eventContent.msgtype;
          break;
      }
      break;
    case "m.room.name":
      const roomName = event.getContent().name;
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        ` hat den Gruppennamen "${roomName}" festgelegt`,
      );
      break;
    case "m.room.topic":
      return;
      const roomTopic = event.getContent().topic;
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        ` hat das Thema "${roomTopic}" festgelegt`,
      );
      break;
    case "org.matrix.msc3401.call":
      type = 2;
      if (eventContent["m.terminated"] === "call_ended") {
        return;
        content = " beendet.";
      } else {
        const ended = events
          .filter(
            (e) =>
              e.getType() === "org.matrix.msc3401.call" &&
              e.getContent()["m.terminated"] === "call_ended" &&
              e.getStateKey() === event.getStateKey(),
          )[0]
          ?.getTs();
        content = React.createElement(
          "div",
          {
            className: "flex align-items-center",
          },
          eventContent["m.type"] === "m.video"
            ? React.createElement(Video)
            : React.createElement(Phone),
          React.createElement(
            "div",
            { className: "padding-left-16" },
            `Anruf${ended ? " (beendet, " + Math.ceil((ended - event.getTs()) / 1000) + " Sekunden)" : ""}`,
          ),
        );
      }
      break;
    case "org.matrix.msc3401.call.member":
      return;
      type = 2;
      if (eventContent["m.calls"].length > 0) {
        content = " beigetreten";
      } else {
        content = " verlassen";
      }
      break;
    case "de.mein-jugendamt.tags":
      return;
      content = React.createElement(
        React.Fragment,
        {},
        senderDisplayName,
        ` hat die Tags angepasst.`,
      );
      break;
    case "org.matrix.msc3381.poll.start":
      const poll = new Poll(event, client, room);
      type = 1;
      content = React.createElement(PollEvent, {
        poll: poll,
        sourceEvent: event,
      });
      break;
    case "org.matrix.msc3381.poll.response":
      return;
    default:
      console.warn(event.getContent());
  }

  if (showExport && !exportable) return;
  if (type === 0) {
    return React.createElement(
      "div",
      {
        className:
          "flex justify-content-center text-small color-grey margin-4 text-align-center",
      },
      content,
    );
  }

  return React.createElement(
    "div",
    {
      className:
        "flex flex-direction-column margin-4 " +
        (isCurrentUserSender
          ? "align-items-flex-end"
          : "align-items-flex-start"),
    },
    React.createElement(
      "div",
      {
        className: "flex max-width-80",
      },
      !isCurrentUserSender &&
        React.createElement(Avatar, { userId: event.getSender() }),
      React.createElement(
        "div",
        {
          className: `max-width-100 flex flex-direction-column ${isCurrentUserSender ? "align-items-flex-end" : "align-items-flex-start"}`,
        },
        !isCurrentUserSender &&
          React.createElement(
            "div",
            { className: "padding-0-8 margin-left-8" },
            senderDisplayName,
          ),
        React.createElement(
          "div",
          {
            className:
              "flex align-items-center gap-12" +
              (showExport && "cursor-pointer"),
            onClick: (x) => {
              showExport && markForExport(event, !markedForExport && content);
            },
          },
          React.createElement(
            "div",
            {
              className:
                !skipBox &&
                "border-radius-16 max-width-100 padding-8-16 word-wrap-break word-break-break-word white-space-pre-line margin-left-8 " +
                  (isCurrentUserSender
                    ? "background-primary-transparent"
                    : "background-light-grey"),
            },
            content,
            React.createElement(
              "div",
              { className: "text-tiny color-grey text-align-right" },
              new Date(event.getTs()).toLocaleTimeString([], {
                hour: "numeric",
                minute: "numeric",
              }),
            ),
          ),
          showExport &&
            React.createElement("div", {
              className:
                "border border-radius-16 height-14 width-14 " +
                (markedForExport && "background-primary"),
            }),
        ),
      ),
    ),
  );
}
