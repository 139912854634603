import React, { useEffect, useState } from "react";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import { Video as VideoIcon } from "./icons/Video";
import { Phone as AudioIcon } from "./icons/Phone";
import { Avatar } from "./Avatar";
import * as sdk from "matrix-js-sdk";
import { Child } from "./icons/Child";
import { Walking } from "./icons/Walking";
import { Shelter } from "./icons/Shelter";
import { request } from "../utils/request";
import { ContactInfo } from "./ContactInfo";
import { RoomName } from "./RoomName";

export function Sidebar({ plus, rooms, selectRoom, selectedRoom }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [contacts, setContacts] = useState([]);

  const client = new SharedMatrix().getClient();

  const fetchContacts = async () => {
    const response = await request("/contacts", "GET").catch(() => {
      // TODO: handle error
      return;
    });
    setContacts(response);
  };

  const createDirectMessage = async (c) => {
    const newRoom = await client
      .createRoom({
        visibility: "private",
        invite: [c.matrix_id],
        initial_state: [
          {
            type: "m.room.encryption",
            state_key: "",
            content: {
              algorithm: "m.megolm.v1.aes-sha2",
            },
          },
        ],
        power_level_content_override: {
          invite: 100,
          kick: 100,
          ban: 100,
          redact: 50,
          state_default: 0,
          events_default: 0,
          users_default: 0,
          events: {
            "m.room.power_levels": 100,
            "de.mein-jugendamt.tags": 100,
            "m.room.history_visibility": 100,
            "m.room.tombstone": 100,
            "m.room.encryption": 100,
            "m.room.name": 50,
            "m.room.message": 0,
            "m.room.encrypted": 0,
            "m.sticker": 50,
            "org.matrix.msc3401.call.member": 0,
          },
        },
      })
      .catch(() => {
        // TODO
      });
    setSearchTerm("");
    selectRoom(client.getRoom(newRoom.room_id));
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const myNormalization = (word) =>
    word
      .replace("ä", "a")
      .replace("Ä", "A")
      .replace("ü", "Ü")
      .replace("Ü", "U")
      .replace("ö", "o")
      .replace("Ö", "O")
      .replace("Ö", "O")
      .toLowerCase();

  const notNullEmtyUndefined = (e) => e !== null && e !== undefined && e !== "";

  const filteredContacts = contacts
    .map((c) => {
      const fields = [
        c.last_name,
        c.first_name,
        c.occupation,
        c.facility_name,
      ].filter(notNullEmtyUndefined);
      const searchTerms = searchTerm.split(" ").filter(notNullEmtyUndefined);
      let score = 0;
      fields.forEach((field) => {
        searchTerms.forEach((term) => {
          if (myNormalization(field).includes(myNormalization(term)))
            score += 1;
        });
      });
      return [c, score];
    })
    .filter((a) => a[1] > 0)
    .toSorted((a, b) => b[1] - a[1])
    .map((a) => a[0]);
  const contactList = filteredContacts.map((c, index) =>
    React.createElement(
      React.Fragment,
      null,
      React.createElement(
        "div",
        {
          key: c.matrix_id,
          className: `flex align-items-center`,
        },
        React.createElement(ContactInfo, { contact: c }),
        React.createElement("div", { className: "flex-1-0-0" }),
        React.createElement(
          "div",
          {
            className: "move-to-end cursor-pointer",
            onClick: () => createDirectMessage(c),
          },
          React.createElement("h1", null, "+"),
        ),
      ),
      filteredContacts.length - 1 === index
        ? null
        : React.createElement("hr", null),
    ),
  );

  const hideMenu = () => {
    Array.from(document.getElementsByClassName("menu")).forEach((element) => {
      element.classList.add("menu-hidden");
    });
  };

  const showMenu = () => {
    Array.from(document.getElementsByClassName("menu")).forEach((element) => {
      element.classList.remove("menu-hidden");
    });
  };

  const filteredRooms = rooms.filter(
    (room) =>
      !searchTerm || room.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const roomsList = filteredRooms.map((room, index) => {
    const existingCall = client.getGroupCallForRoom(room.roomId);
    const type = room
      .getLiveTimeline()
      .getState(sdk.EventTimeline.FORWARDS)
      ?.getStateEvents("de.mein-jugendamt.tags", "")
      ?.getContent()?.type;
    return React.createElement(
      React.Fragment,
      null,
      React.createElement(
        "div",
        {
          key: room.roomId,
          onClick: () => selectRoom(room),
          className: `flex align-items-center cursor-pointer ${room === selectedRoom && " selected"}`,
        },
        React.createElement(
          "div",
          { className: "padding-right-8" },
          type === "guest" && React.createElement(Child),
          type === "external" && React.createElement(Walking),
          type === "internal" && React.createElement(Shelter),
          type === undefined &&
            room.getAvatarFallbackMember() &&
            React.createElement(Avatar, {
              userId: room.getAvatarFallbackMember().userId,
            }),
        ),
        React.createElement(
          "h4",
          {},
          React.createElement(RoomName, {
            room: room,
            key: room.getMembers().join(","),
          }),
        ),
        React.createElement("div", { className: "flex-1-0-0" }),
        existingCall &&
          (existingCall.type === "m.voice"
            ? React.createElement(AudioIcon)
            : React.createElement(VideoIcon)),
        room.getUnreadNotificationCount() > 0 &&
          React.createElement(
            "div",
            {
              className:
                "background-orange padding-4-8 border-radius-32 text-small",
            },
            room.getUnreadNotificationCount(),
          ),
        React.createElement(
          "svg",
          {
            className: "mobile-only padding-left-16",
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 320 512",
          },
          React.createElement("path", {
            d: "M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z",
          }),
        ),
      ),
      filteredRooms.length - 1 === index
        ? null
        : React.createElement("hr", null),
    );
  });
  return React.createElement(
    "div",
    { className: "sidebar active" },
    React.createElement(
      "div",
      { className: "flex align-items-center" },
      React.createElement(
        "div",
        null,
        React.createElement("h1", null, "Chats"),
      ),
      React.createElement(
        "div",
        {
          className: "move-to-end cursor-pointer",
          onClick: plus,
        },
        React.createElement("h1", null, "+"),
      ),
    ),
    React.createElement("input", {
      placeholder: "Suche",
      value: searchTerm,
      onChange: (event) => setSearchTerm(event.target.value),
      className: "border border-color-grey",
      onFocus: hideMenu,
      onBlur: showMenu,
    }),
    roomsList,
    searchTerm &&
      React.createElement(
        React.Fragment,
        {},
        React.createElement("h2", null, "Kontakte"),
        contactList,
      ),
  );
}
