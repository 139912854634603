import { Controller } from "@hotwired/stimulus";
import { request } from "../utils/request";
import { SharedMatrix } from "../mks-matrix/shared-matrix";

// Connects to data-controller="logout"
export default class extends Controller {
  connect() {}

  async logout() {
    const logoutChannel = new BroadcastChannel("logout_channel");
    logoutChannel.postMessage("ANIMATION");
    await request("/session", "DELETE");
    await new SharedMatrix().logout();
    logoutChannel.postMessage("LOGOUT");
  }
}
