import React, { useEffect, useState } from "react";
import { request } from "../utils/request";
import { ContactInfo } from "./ContactInfo";

export function ContactInfoFromUser({ user }) {
  const [contact, setContact] = useState(null);
  const fetchUser = async () => {
    const response = await request(
      `/contacts/from_matrix?matrix_id=${user.userId}`,
      "GET",
    );
    setContact(response);
  };
  useEffect(() => {
    fetchUser();
  }, [user]);
  if (contact) return React.createElement(ContactInfo, { contact: contact });
}
