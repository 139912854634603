import { encryptAttachment } from "matrix-encrypt-attachment";
import { SharedMatrix } from "../mks-matrix/shared-matrix";

export default function (file, room) {
  const client = new SharedMatrix().getClient();
  let msgtype = "m.file";
  if (file.type.startsWith("image/")) {
    msgtype = "m.image";
  } else if (file.type.startsWith("audio/")) {
    msgtype = "m.audio";
  }
  return new Promise((resolve, reject) => {
    file.arrayBuffer().then((buffer) =>
      encryptAttachment(buffer).then((encrypted) =>
        client.uploadContent(encrypted.data).then((response) => {
          client.sendMessage(room.roomId, {
            msgtype: msgtype,
            body: file.name,
            file: {
              url: response.content_uri,
              ...encrypted.info,
            },
            info: {
              mimetype: file.type,
              size: file.size,
            },
          });
          resolve();
        }),
      ),
    );
  });
}
