import React, { StrictMode } from "react";
import { useState, useEffect } from "react";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import { Chat } from "./Chat";

export function BlockUnlessMatrixRunning({ showWhenRunning, showWhenBlocked }) {
  const [running, setRunning] = useState(false);
  useEffect(() => {
    const onRunningEncrypted = () => {
      setRunning(true);
    };
    const matrix = new SharedMatrix().getMatrix();
    matrix.onRunningEncrypted(onRunningEncrypted);

    matrix.runningEncrypted().then((matrixRunning) => {
      if (matrixRunning) setRunning(true);
    });
    return () => {};
  }, []);
  if (running) {
    return React.createElement(Chat, null);
  } else {
    return React.createElement(
      React.Fragment,
      null,
      React.createElement(
        "div",
        { className: "sidebar active" },
        React.createElement(
          "div",
          { className: "flex align-items-center" },
          React.createElement(
            "div",
            null,
            React.createElement("h1", null, "Chats"),
          ),
        ),
        "Die Verschlüsselung steht noch nicht bereit.",
      ),
      React.createElement(
        "div",
        { className: "main" },
        "Die Verschlüsselung steht noch nicht bereit.",
      ),
    );
  }
}
