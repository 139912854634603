import React, { useEffect, useRef, useState } from "react";
import { Message } from "./Message";
import { ContactInfoFromUser } from "./ContactInfoFromUser";
import { Phone } from "./icons/Phone";
import { Video } from "./icons/Video";
import { Warning } from "./icons/Warning";
import { Stop } from "./icons/Stop";
import { Export } from "./icons/Export";
import { RoomName } from "./RoomName";
import { SharedMatrix } from "../mks-matrix/shared-matrix";
import encryptAndUploadFile from "../utils/encryptAndUploadFile";
import * as sdk from "matrix-js-sdk";
import { Avatar } from "./Avatar";
import { Loader } from "./Loader";
import { Child } from "./icons/Child";
import { Walking } from "./icons/Walking";
import { Shelter } from "./icons/Shelter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePollHorizontal } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "./Modals/Modal";
import { PollStartEvent } from "matrix-js-sdk/lib/extensible_events_v1/PollStartEvent";

const chunks = [];
export function Room({ room, unselect }) {
  const [warning, setWarning] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [audio, setAudio] = useState(null);
  const [poll, setPoll] = useState(false);
  const [textOnly, setTextOnly] = useState(false);
  const [question, setQuestion] = useState("");
  const [pollAnswers, setPollAnswers] = useState(["Antwort 1", "Antwort 2"]);
  const [showInfo, setShowInfo] = useState(false);
  const [messageMarkedMap, setMessageMarkedMap] = useState(new Map());
  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const messageInputRef = useRef(null);
  const client = new SharedMatrix().getClient();
  const rm = room.getMembers();
  const existingCall = client.getGroupCallForRoom(room.roomId);
  const liveTimeline = room.getLiveTimeline();
  const type = room
    .getLiveTimeline()
    .getState(sdk.EventTimeline.FORWARDS)
    ?.getStateEvents("de.mein-jugendamt.tags", "")
    ?.getContent()?.type;
  const events = liveTimeline.getEvents().toSorted((a, b) => {
    return a.getTs() - b.getTs();
  });

  useEffect(() => {
    chunks.length = 0;
  }, []);

  const getAndSetRoomMembers = async () => {
    const p_userIds = rm.map((member) => member.userId);
    const asdf = await client.getCrypto().getUserDeviceInfo(p_userIds);
    const any = rm.filter((m) => {
      return asdf.get(m.userId).size < 1;
    });
    if (!!any.length) {
      setWarning(
        "Einer oder mehrere Benutzer haben kein aktives Gerät und werden neue Nachrichten nicht lesen können.",
      );
    } else setWarning(null);
  };

  useEffect(() => {
    getAndSetRoomMembers();
  }, [room]);

  const handleFiles = (e) => {
    setFileList(e.target.files);
  };

  const voiceCall = async () => {
    doCall("m.voice");
  };

  const loadOlderMessage = async () => {
    const a = await !client.paginateEventTimeline(liveTimeline, {
      backwards: true,
      limit: 10,
    });
  };

  const videoCall = async () => {
    doCall("m.video");
  };

  const doCall = async (type) => {
    await client.waitUntilRoomReadyForGroupCalls(room.roomId);
    const call = await client.createGroupCall(
      room.roomId,
      type,
      false,
      "m.ring",
    );
    call.enter();
  };

  const onRecorderStopped = async (recorder) => {
    recorder.stream.getTracks().forEach(function (track) {
      track.stop();
    });
    const file = new File(chunks, "Sprachaufnahme", { type: "audio/flac" });
    await encryptAndUploadFile(file, room);
    chunks.length = 0;
    setAudio(null);
  };

  const recordAudio = async () => {
    const recorder = new MediaRecorder(
      await navigator.mediaDevices.getUserMedia({ audio: true }),
    );

    recorder.onstop = () => onRecorderStopped(recorder);

    recorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };

    recorder.start();
    setAudio(recorder);
  };

  const stopRecording = () => {
    console.log(audio);
    audio.stop();
  };

  const joinExistingCall = () => {
    existingCall.enter();
  };

  const send = () => {
    const messageContent = messageInputRef.current.innerText;
    if (messageContent.trim()) {
      client.sendTextMessage(
        room.roomId,
        messageContent.replace(/^\s+|\s+$/g, ""),
      );
      messageInputRef.current.innerText = "";
    }
  };

  const keyDown = (e) => {
    if (!messageInputRef) return;

    if (e.code === "Enter" && !e.shiftKey) {
      send();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (messageInputRef.current) {
      messageInputRef.current.innerText = "";
    }
    event = events[0];
    client.sendReadReceipt(event);
  }, [room]);

  const markMessageForExport = (event, val) => {
    setMessageMarkedMap((prevState) => {
      const cp = new Map(prevState);
      cp.set(event, val);
      return cp;
    });
  };

  const messages = events.reverse().map((event) => {
    return React.createElement(
      "div",
      { key: event.getId() },
      React.createElement(
        Message,
        {
          room: room,
          key: event,
          event: event,
          events: events,
          showExport: showExport,
          markForExport: markMessageForExport,
          markedForExport: messageMarkedMap.get(event),
        },
        null,
      ),
    );
  });

  const showExistingCall =
    existingCall &&
    Array.from(existingCall.participants)
      .map(([key, value]) => key.userId !== client.getUserId())
      .reduce((a, b) => a && b, true);

  let existingCallElement = null;
  if (showExistingCall) {
    existingCallElement = React.createElement(
      "div",
      {
        className:
          "flex align-items-center background-primary padding-8-16 fill-white border-radius-6",
      },
      React.createElement(
        "div",
        { className: "padding-right-16" },
        existingCall.type === "m.voice"
          ? React.createElement(Phone)
          : React.createElement(Video),
      ),
      Array.from(existingCall.participants)
        .map(([key, value]) => {
          return key.name;
        })
        .join(", "),
      React.createElement(
        "button",
        {
          className: "background-green move-to-end cursor-pointer",
          onClick: joinExistingCall,
        },
        "beitreten",
      ),
    );
  }

  const textExport = () => {
    const header = `${room.name}\n==================================\n`;
    let body = "";
    messageMarkedMap.forEach((value, event) => {
      if (value) {
        body = body.concat(
          `${event.sender.name} (${new Date(event.getTs()).toLocaleString()}): ${value}\n-----------------------------\n`,
        );
      }
    });
    const link = document.createElement("a");
    const file = new Blob([header, body], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    link.download = `${room.name}.txt`;
    link.click();
  };

  const upload = async () => {
    setLoadingFileUpload(true);
    const promises = Array.from(fileList).map((file) =>
      encryptAndUploadFile(file, room),
    );
    await Promise.all(promises);
    setFileList([]);
    setLoadingFileUpload(false);
  };

  const fileListPreview = Array.from(fileList).map((file) =>
    React.createElement(
      "div",
      { className: "background-primary-transparent padding-6 border-radius-6" },
      file.name,
    ),
  );

  const createPoll = () => {
    const pollEvent = PollStartEvent.from(
      question,
      pollAnswers,
      "org.matrix.msc3381.poll.disclosed",
      1,
    ).serialize();
    client.sendEvent(room.roomId, pollEvent.type, pollEvent.content);
    setPoll(false);
  };

  if (!showInfo || showExport)
    return React.createElement(
      React.Fragment,
      {},
      poll &&
        React.createElement(
          Modal,
          {
            title: "Umfrage",
            close: () => setPoll(false),
          },
          React.createElement("input", {
            placeholder: "Frage",
            onChange: (event) => setQuestion(event.target.value),
            value: question,
          }),
          pollAnswers.map((a, i) =>
            React.createElement(
              "div",
              { className: "flex" },
              React.createElement("input", {
                value: a,
                onChange: (event) =>
                  setPollAnswers(
                    pollAnswers.map((e, j) =>
                      i === j ? event.target.value : e,
                    ),
                  ),
              }),
              React.createElement("div", {}, "x"),
            ),
          ),
          React.createElement(
            "div",
            { onClick: () => setPollAnswers([...pollAnswers, ""]) },
            "+",
          ),
          React.createElement(
            "button",
            {
              onClick: createPoll,
            },
            "create",
          ),
        ),
      React.createElement(
        "div",
        {
          id: "room",
          className: "main main-no-padding flex flex-direction-column active",
        },
        React.createElement(
          "div",
          { className: "padding-0-16" },
          React.createElement(
            "div",
            { className: "flex align-items-center padding-12-0" },
            !showExport &&
              React.createElement(
                "div",
                {
                  onClick: unselect,
                  className: "mobile-only cursor-pointer padding-right-16",
                },
                React.createElement(
                  "svg",
                  {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 320 512",
                  },
                  React.createElement("path", {
                    d: "M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z",
                  }),
                ),
              ),
            React.createElement(
              "div",
              { className: "flex align-items-center full-width" },
              !showExport &&
                warning &&
                React.createElement(Warning, {
                  className:
                    "padding-right-8 fill-red cursor-pointer flex-shrink-0",
                  handleClick: () => {
                    alert(warning);
                  },
                }),
              React.createElement(
                "div",
                { className: "padding-right-8" },
                type === "guest" && React.createElement(Child),
                type === "external" && React.createElement(Walking),
                type === "internal" && React.createElement(Shelter),
                type === undefined &&
                  room.getAvatarFallbackMember() &&
                  React.createElement(Avatar, {
                    userId: room.getAvatarFallbackMember().userId,
                  }),
              ),
              React.createElement(
                "div",
                {
                  onClick: () => setShowInfo(true),
                  className: "cursor-pointer",
                },
                React.createElement(
                  "h4",
                  { className: "margin-0" },
                  React.createElement(RoomName, {
                    room: room,
                    key: room.getMembers().join(","),
                  }),
                ),
                !showExport &&
                  React.createElement(
                    "div",
                    { className: "color-grey text-tiny" },
                    "zur Info",
                  ),
              ),
              React.createElement("div", { className: "flex-1-0-0" }, ""),
              !showExport
                ? !existingCall &&
                    React.createElement(
                      "div",
                      { className: "flex gap-12" },
                      React.createElement(
                        "div",
                        {
                          onClick: voiceCall,
                          className: "cursor-pointer",
                        },
                        React.createElement(Phone),
                      ),
                      React.createElement(
                        "div",
                        { onClick: videoCall, className: "cursor-pointer" },
                        React.createElement(Video),
                      ),
                    )
                : React.createElement(
                    "svg",
                    {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 384 512",
                      className: "cursor-pointer padding-right-16",
                      onClick: () => {
                        setShowExport(false);
                      },
                    },
                    React.createElement("path", {
                      d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
                    }),
                  ),
            ),
          ),
          React.createElement("hr", null),
          existingCallElement,
        ),
        React.createElement(
          "div",
          {
            className:
              "flex-1-0-0 flex flex-direction-column-reverse overflow-auto",
            id: "messages",
          },
          messages,
          React.createElement(
            "button",
            { onClick: loadOlderMessage },
            "ältere Nachrichten laden",
          ),
        ),
        showExport
          ? React.createElement(
              "button",
              {
                className: "full-width margin-bottom-4",
                onClick: textExport,
              },
              `Download ${room.name}.txt`,
            )
          : audio
            ? React.createElement(
                "div",
                {
                  onClick: stopRecording,
                  className:
                    "fill-red padding-0-16 margin-top-bottom-4 flex align-items-center justify-content-flex-end",
                  style: { height: "42px" },
                },
                React.createElement(Stop),
              )
            : !fileList.length
              ? React.createElement(
                  "div",
                  {
                    className:
                      "padding-0-16 margin-top-bottom-4 flex align-items-center",
                  },
                  !(textOnly || messageInputRef.current?.innerText.length) &&
                    React.createElement(
                      "label",
                      { htmlFor: "fileInput" },
                      React.createElement(
                        "svg",
                        {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 448 512",
                          className: "cursor-pointer padding-right-16",
                        },
                        React.createElement("path", {
                          d: "M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z",
                        }),
                      ),
                    ),
                  !(textOnly || messageInputRef.current?.innerText.length) &&
                    React.createElement(FontAwesomeIcon, {
                      icon: faSquarePollHorizontal,
                      className: "cursor-pointer padding-right-16",
                      onClick: () => setPoll(true),
                    }),
                  React.createElement("input", {
                    type: "file",
                    id: "fileInput",
                    hidden: true,
                    onChange: handleFiles,
                    multiple: true,
                  }),
                  React.createElement(
                    "div",
                    {
                      className:
                        "flex-1-0-0 flex align-items-center border border-color-grey border-radius-32 padding-8-16",
                    },
                    React.createElement("div", {
                      // TODO: move to styles
                      style: {
                        maxHeight: "6em",
                        overflow: "auto",
                      },
                      className:
                        "flex-1-0-0 word-wrap-break word-break-break-word",
                      contentEditable: true,
                      ref: messageInputRef,
                      onKeyDown: (e) => keyDown(e),
                      onFocus: () => {
                        setTextOnly(true);
                      },
                      onBlur: () => {
                        setTextOnly(false);
                      },
                    }),
                    React.createElement(
                      "div",
                      {
                        className: "move-to-end cursor-pointer",
                        onClick: send,
                      },
                      React.createElement(
                        "svg",
                        {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 512 512",
                        },
                        React.createElement("path", {
                          d: "M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z",
                        }),
                      ),
                    ),
                  ),
                  !(textOnly || messageInputRef.current?.innerText.length) &&
                    React.createElement(
                      "svg",
                      {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 384 512",
                        className: "padding-left-16 cursor-pointer",
                        onClick: recordAudio,
                      },
                      React.createElement("path", {
                        d: "M192 0C139 0 96 43 96 96V256c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V216z",
                      }),
                    ),
                )
              : React.createElement(
                  "div",
                  {
                    className: "padding-0-16 margin-top-bottom-4",
                  },
                  React.createElement(
                    "div",
                    {
                      className:
                        "flex-1-0-0 flex align-items-center border border-color-grey border-radius-32 padding-8-16",
                    },
                    React.createElement(
                      "div",
                      {
                        className: "flex align-items-center flex-wrap gap-12",
                      },
                      fileListPreview,
                    ),
                    loadingFileUpload
                      ? React.createElement(
                          "div",
                          { className: "move-to-end" },
                          React.createElement(Loader),
                        )
                      : React.createElement(
                          "div",
                          {
                            className: "move-to-end cursor-pointer",
                            onClick: upload,
                          },
                          React.createElement(
                            "svg",
                            {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 512 512",
                            },
                            React.createElement("path", {
                              d: "M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z",
                            }),
                          ),
                        ),
                  ),
                ),
      ),
    );

  return React.createElement(
    "div",
    {
      id: "room",
      className: "main main-no-padding flex flex-direction-column active",
    },
    React.createElement(
      "div",
      { className: "padding-0-16" },
      React.createElement(
        "div",
        { className: " flex align-items-center" },
        React.createElement(
          "div",
          { className: "flex align-items-center full-width" },
          React.createElement(
            "h4",
            {},
            React.createElement(RoomName, { room: room }),
          ),
        ),
        React.createElement(
          "div",
          {
            onClick: () => setShowInfo(false),
            className: "cursor-pointer move-to-end",
          },
          React.createElement(
            "svg",
            {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 384 512",
            },
            React.createElement("path", {
              d: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
            }),
          ),
        ),
      ),
      React.createElement("hr", null),
    ),
    React.createElement(
      "div",
      { className: "padding-0-16" },
      React.createElement(
        "p",
        null,
        rm.map((member) =>
          React.createElement(
            "div",
            {},
            React.createElement(ContactInfoFromUser, { user: member }),
          ),
        ),
        React.createElement(
          "div",
          {
            onClick: () => setShowExport(true),
            className: "cursor-pointer",
          },
          React.createElement(Export),
        ),
      ),
    ),
  );
}
