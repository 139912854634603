import React from "react";

export function ContactInfo({ contact }) {
  const renderContactType = (contact) => {
    let label = "";
    let color = "";
    if (contact.internal) {
      label = contact.facility_name;
      color = "background-primary";
    } else if (contact.external) {
      label = "Leistungserbringer";
      color = "background-orange";
    } else if (contact.guest) {
      label = "Familienmitglied";
      color = "background-guest";
    }
    return React.createElement(
      "div",
      { className: "border-radius-16 padding-4-8 " + color },
      label,
    );
  };

  return React.createElement(
    "div",
    null,
    React.createElement(
      "div",
      { className: "flex align-items-center" },
      React.createElement(
        "b",
        { className: "padding-right-8" },
        `${contact.first_name} ${contact.last_name}`,
      ),
      renderContactType(contact),
    ),
    React.createElement(
      "div",
      { className: "padding-top-8" },
      contact.occupation,
    ),
  );
}
